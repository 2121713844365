export enum Permission {
  ViewUsers = 0,
  ManageUsers = 1,
  ViewAccessGroups = 2,
  ManageAccessGroups = 3,
  ViewCategories = 4,
  ManageCategories = 5,
  ViewPaymentMethods = 6,
  ManagePaymentMethods = 7,
  ViewMerchandises = 8,
  ManageMerchandises = 9,
  ViewSales = 10,
  ManageSales = 11,
};